// Promo card
@import '../../../styles/component.scss';

.card {
  composes: card from global;

  border: none;
  display: flex;
  align-items: stretch;
}

.cardBody {
  composes: card-body from global;

  border: none;
  padding-left: 0;
  padding-right: 0;
}

.cardTitle {
  composes: card-title h4 from global;

  border: none;
  margin-bottom: 0.5rem;
}

.cardText {
  color: color('shark');
  border: none;
}

.imageWrap {
  composes: image-rect image-rect-4-3 from global;
  composes: imageColorWrap from '../../Asset/Asset.module.scss';
}

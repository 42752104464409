// Global footer
@import '../../styles/component.scss';

.globalLanguageDropdownToggle {
  @include buttonReset();

  color: color('white');
  font-size: $font-size-sm;

  &[aria-expanded="true"] {
    display: none;
  }
}

.globalLanguageDropdown {
  composes: list-unstyled from global;

  margin-top: -1.375rem;
  background-color: color('black');
  padding: 0.25rem !important;

  * {
    color: color('white');
    font-size: $font-size-sm;
    cursor: pointer;
  }

  .languageSelectionWrap & {
    padding-left: 1rem !important;
    margin-left: 0.5rem !important;
  }
}
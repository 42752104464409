// Live course details
@import '../../styles/component.scss';

.pageLiveCourse {
  flex-grow: 1;
  composes: theme-virtual-training from global;

  @include media-breakpoint-down(md) {
    .moduleContainerWrap {
      width: 100%;
      padding: 0 1rem;
    }

    .detailsCol,
    .registerCol {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @include media-breakpoint-down(xs) {
    .moduleContainerWrap {
      max-width: 100%;
      padding: 0 1rem;
      overflow: hidden;
    }

    .detailsCol,
    .registerCol {
      padding-left: 2rem !important;
      padding-right: 2rem !important;

      &:before,
      &:after {
        content: none;
      }
    }

    .registerCol {
      margin: 0;
      max-width: 100%;
      flex: auto;
    }

    .registerContent,
    .detailsContent {
      max-width: 35rem;
    }
  }
}

/* Phone/Tablet Landscape Orientation */
.pageLiveCourse .moduleContainerWrap {
  // iPhone 6/7/8/X
  @media only screen
    and (min-device-width: 375px)
    and (max-device-width: 812px)
    // and (-webkit-min-device-pixel-ratio: 3)
    and (orientation: landscape) {
    .detailsCol,
    .registerCol {
      &:before,
      &:after {
        content: none !important;
      }
    }
  }

  // iPad
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  // and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .detailsCol,
    .registerCol {
      &:before,
      &:after {
        content: none;
      }
    }

    .detailsCol {
      padding-left: 7rem;
    }

    .registerCol {
      padding-right: 2rem;
    }
  }
}

.moduleContainerWrap {
  composes: container h-100 bg-light from global;
}

.moduleContainerRow {
  composes: row h-100 from global;
}

.detailsCol:before {
  content: '';
  left: -50vw;
  width: 50vw;
}

.registerCol {
  @include media-breakpoint-up(md) {
    height: 100%;
  }

  &:after {
    content: '';
    right: -50vw;
    width: 50vw;
  }
}

.detailsCol,
.registerCol {
  &:before,
  &:after {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: inherit;
  }

  @include media-breakpoint-down(sm) {
    &:before {
      left: -50%;
      width: 50%;
      content: '';
    }

    &:after {
      right: -50%;
      width: 50%;
      content: '';
    }
  }
}

.detailsCol {
  composes: section position-relative col-24 col-md-14 from global;
}

.registerCol {
  composes: section position-relative bg-light col-24 col-xs-20 offset-xs-2 col-sm-16 offset-sm-4 col-md-10 offset-md-0 from global;
}

.registerContent {
  composes: text-center d-flex align-items-center justify-content-center w-100 h-lg-100 flex-column from global;

  max-height: 700px;

  > div {
    width: 100%;
  }
}

.detailsContent {
  composes: row from global;

  position: relative;
}

.imageWrap {
  composes: col-9 col-xs-6 col-sm-3 col-md-6 from global;

  margin-bottom: 2rem;
}

.detailsInnerContent {
  composes: col-24 col-sm-16 col-md-24 d-flex flex-column from global;
}

.name {
  composes: h2 from global;

  margin-bottom: 1.875rem;
}

.descriptionHeader {
  composes: h4 from global;

  color: color('black') !important;
  margin-bottom: 1.875rem;
  order: 1;
}

.durationWrap {
  order: 2;

  @include media-breakpoint-up(md) {
    order: 4;
    margin-top: 1.875rem;
  }
}

.durationHeader {
  composes: h5 from global;

  margin-bottom: 0;
}

.highlightsWrap {
  composes: mb-0 from global;

  margin-top: 1.875rem;
  order: 3;
}

.highlightsHeader {
  composes: h5 from global;

  margin-bottom: 0;
}

.loginCtaWrap {
  text-align: center;
}

.btnSubmit {
  composes: btn btn-grey-bg w-100 from global;

  &[disabled],
  &[disabled]:hover {
    background-color: $colorPandaGrayMedium;
    color: $colorPandaGrayDark;
  }
}

.courseHighlightsList {
  composes: mb-0 from global;
  padding-left: $grid-gutter-width;
}

.languageSelectionWrap {
  margin-bottom: 1.875rem;
}

.timeSelectionWrap {
  margin-bottom: 0.7rem;
}

.loginHeaderTextWrap {
  margin-bottom : 0.625rem;
}

.loginHeaderText {
}

.loginPromptWrap {
  margin-bottom: 1.875rem;
}

.createAccountCtaWrap {
  margin-top: 1.25rem;
  text-align: center;
}

.createAccountCta {
  @include fontLinkColorReset();
}

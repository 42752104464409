@import '../../styles/component.scss';

.searchInputGroup {
  composes: input-group m-0 from global;

  background: white;

    .btnSearch {
      border-color: transparent;
      padding-top: 0.175rem;
      padding-bottom: 0.175rem;
      min-height: initial !important;
    }

    &:focus-within {
      .searchInput {
        display: inline-block;
      }

      .searchInput,
      .btnSearch {
        border-color: $input-focus-border-color;
      }
    }

    @include media-breakpoint-up(xs) {
      .btnSearch {
        border-color: $input-focus-border-color;
      }
    }
}

.searchInputInnerWrap {
  /* Necessary for the position: absolute we use for search suggestions to have full width */
  position: relative;

  &.sm {
    max-width: 244px;
  }

  &:focus-within {
    .searchInput {
      display: inline-block !important;
    }
  }
}

.searchInputGroupIconWrap {
  composes: input-group-append from global;
}

.searchBarWrap {
  composes: ml-auto mr-2 mr-lg-4 from global;
}

.searchInput {
  composes: form-control from global;

  border-right-color: transparent !important;
  display: none !important;
  box-shadow: none;

  @include media-breakpoint-up(xs) {
    display: inline-block !important;;
  }

  &:focus {
    box-shadow: none;
    display: inline-block;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

.btnSearch {
  composes: form-control btn from global;

  background-color: transparent !important;
  border-left-color: transparent !important;

  @include media-breakpoint-up(lg) {
    border-color: color('pandaGrayDark');
  }
}

.middleButton {
  border-right-color: transparent !important;
}

.btnSearchSmall {
  composes: form-control-sm btn-sm from global;
  composes: btnSearch;

  &:hover {
    &:focus,
    &:focus-within {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.btnSearchLarge {
  composes: form-control-lg btn-lg from global;
  composes: btnSearch;
}

.searchInputSmall {
  composes: form-control-sm from global;
  composes: searchInput;
}

.searchInputLarge {
  composes: form-control-lg from global;
  composes: searchInput;
  flex: 1;
}

/********* Suggestion styles ********/
.searchSuggestionContainer {
  background-color: $white;
  border: 1px solid $colorStone;
  position: absolute;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  z-index: 2;
}

.searchSuggestion {
  display: block;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;

  color: $black;

  &:hover {
    color: $black;
  }

  .sm & {
    padding-left: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  &:hover {
    background-color: $colorPandaGrayExtraLight;
  }

  &.activeSuggestion {
    background-color: $colorPandaGrayMedium;
  }
}

@import '../../styles/component.scss';

.moduleFeaturedCourseWrap {
  background-color: color('pandaGray');

  &:not(.resumeCourse) {
    .courseProgressWrap {
      display: block;

      margin-bottom: 2rem;
    }
  }
}

.moduleContainerWrap {
  composes: container from global;
}

.moduleContainerRow {
  composes: row from global;
}

.courseImageCol {
  composes: col-8 col-xs-6 col-sm-3 col-md-4 from global;

  margin-bottom: $grid-gutter-width;
}

.courseDetailsCol {
  composes: col-24 col-xs-18 col-sm-9 offset-sm-1 col-md-8 from global;
}

.courseProgressCol {
  composes: col-24 col-xs-18 offset-xs-6 col-sm-11 offset-sm-0 from global;
}

.courseNameWrap {
  margin-bottom: 1.25rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0.625rem;
  }
}

.courseName {
  composes: h4 mb-0 from global;
}

.topicsWrap {
  composes: h6 from global;

  margin-bottom: 1rem;
}

.sectionTitle {
  composes: section-header-text from global;
}

.courseProgressHeader {
  composes: h6 from global;

  margin-bottom: 0.5rem;
}

.learningObjectivesHeader {
  font-weight: bold;
}

.courseProgressWrap {
  margin-top: 1.25rem;

  @include media-breakpoint-up(sm) {
    margin-top:0;
    margin-bottom: 0.625rem;
  }
}

.courseCtaWrap {
  margin-top: 0.625rem;
  
  @include media-breakpoint-up(sm) {
    margin-top: 0.875rem;
  }

  .courseDetailsCol & {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .courseProgressCol & {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.learningObjectivesWrap {
  @include media-breakpoint-up(sm) {
    margin-bottom: 0.625rem;
  }
}


.courseCta {
  composes: btn btn-grey-bg from global;
}

.progress {
  composes: progress from global;

  background-color: color('white');
  height: 0.5rem;
}

.progressBar {
  composes: progress-bar from global;

  height: inherit;
  background-color: color('black');
  width: 75%;
}
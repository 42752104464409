.video-wrap {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
     }

    video {
        width: 100%    !important;
        height: auto   !important;
        
        &.vertical {
            /* override other styles to make responsive */
            width: auto    !important;
            height: 100%   !important;
        }
    }
}
@import '../../styles/component.scss';

.pageCourseWrap {
  composes: position-relative from global;

  z-index: 1;
}

.heroSection {
  composes: section theme-page-course position-relative from global;
}

.heroContainer {
  composes: container from global;
}

.heroRow {
  composes: row from global;
}

.heroImageCol {
  composes: col-8 col-xs-6 col-sm-3 col-lg-4 from global;

  margin-bottom: $grid-gutter-width;
}

.heroContentCol {
  composes: col-24 col-xs from global;
}

.heroNameCol {
  composes: col-24 col-xs-24 col-sm-13 from global;

  margin-bottom: 1.875rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }
}

.heroDescriptionCol {
  composes: col-24 col-xs-24 col-sm-11 from global;
}

.heroName {
  composes: h2 from global;

  margin-bottom: 1.25rem;
}

.heroOverview {
  composes: h5 from global;

  text-transform: uppercase;
  margin-bottom: 0.625rem;
}

.heroTopicsWrap {
  composes: h6 from global;

  margin: 0 1.25rem 0 0;
  white-space: nowrap;
}

.heroDescriptionWrap {
  margin-bottom: 1.25rem;
}

.heroProgressWrap {
  display: none;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    display: block;
  }
}

.heroProgress {
  composes: h6 from global;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  white-space: nowrap;
  text-indent: 1.6rem;
  transform: translateX(-100%);
  animation: slideIn 1.2s cubic-bezier(0.89, 0.07, 0.45, 0.94) 0.75s 1 forwards;

  &::before {
    content: '';
    position: absolute;
    top: 0.2em;
    left: 0;
    width: 1rem;
    height: 1rem;
    background: transparent url(/images/assets/check-mark-circled.svg) center / contain no-repeat;
    z-index: 999999;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    span:last-child {
      text-indent: 0.5rem;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.heroCta {
  composes: btn btn-light from global;
}

.learningObjectivesCol {
  composes: col-24 col-sm-9 col-lg-11 from global;
}

.tableOfContentsCol {
  composes: col-24 col-sm-12 offset-sm-3 col-lg-9 offset-lg-4 from global;

  margin-top: 3.125rem;

  @include media-breakpoint-up(xs) {
    margin-top: 4.625rem;
  }

  @include media-breakpoint-up(sm) {
    margin-top: 0;
  }
}

.contentSection {
  composes: section from global;
}

.contentContainer {
  composes: container from global;
}

.contentRow {
  composes: row from global;
}

.courseExpectationsWrap {
  margin-top: 1.875rem;
}

.courseExpectationsHeader {
  composes: h5 from global;

  margin-bottom: 0.625rem;
}

.tableOfContentsHeader {
  composes: h4 from global;

  margin-bottom: 1.875rem;
}

.learningObjectivesHeader {
  composes: h4 from global;

  margin-bottom: 1.875rem;
}

.tableOfContentsHeader,
.courseExpectationsHeader,
.learningObjectivesHeader {
  word-wrap: break-word; // TODO: Remove when localization is setup in storybook
}

.tableOfContents {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.topicWrap {
  margin: 0;

  &:first-of-type {
    margin: 0 !important;
  }

  & + & {
    margin-top: 1.25rem;
  }

  &:nth-child(n + 8) {
    display: none;
  }
}

.topicName {
  @include fontBaseReset();

  line-height: 1.5;
  position: relative;
  display: block;
  margin: 0;
  padding-left: 1.5rem;

  &:hover {
    text-decoration: underline;
  }

  &::before {
    position: absolute;
    left: 0;
    width: 0.5rem;
    height: 0.5rem;
    z-index: 999999;
    cursor: pointer;
    content: '• ';
  }
}

.topicNameActive {
  &::before {
    background-image: url(/images/assets/check-mark-active.svg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0.5em;
    content: '';
  }
}

.seeMoreTextLinkWrap {
  text-align: center;
  margin-top: $grid-gutter-width;
}

.seeMoreTextLink {
  @include fontBase();
  @include sharpGroteskMedium();

  color: $black;
  font-size: $font-size-sm;
  text-decoration: underline;
  line-height: 1.5;
  text-align: center;
  margin-top: $grid-gutter-width;

  &:hover {
    color: $black;
    text-decoration: none;
  }
}

.showAllTopics {
  .topicWrap {
    display: block;
  }

  .seeMoreTextLinkWrap {
    display: none;
  }
}

.courseProgressWrap {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.progress {
  composes: progress from global;
}

.progressBar {
  composes: progress-bar from global;

  height: inherit;
  //background-color: color('black') !important;
  width: 0%;
}

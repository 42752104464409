/* Custom components */
// Import from /node_modules/
// @import '~react-calendar/dist/Calendar.less';

// Time select
@import '../../styles/component.scss';

/*
.react-calendar {
  @include fontAtlas();
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
  border: 0;

  .react-calendar__navigation__arrow {
    height: 90%;
    font-size: 1.5rem;
    color: color('shark');
    width: 14.2857%;

    @include media-breakpoint-up(md) {
      width: 28.5714%;
    }
  }

  .react-calendar__month-view__weekdays {
    font-size: $font-size-sm;
    text-transform: none;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: none;
  }

  .react-calendar__month-view__days__day {
    padding: 0;
    display: flex;
    justify-content: center;

    abbr {
      font-size: $font-size-sm;
      display: block;
      width: 3em;
      height: 3em;
      border-radius: 50%;
      line-height: 3;
    }

    &:focus {
      outline: none;
    }

    &.react-calendar__tile:disabled {
      background-color: transparent;
    }

    &:disabled,
    &.react-calendar__tile--past {
      pointer-events: none;
      cursor: default;
      background: transparent;
    }

    &:disabled,
    &.react-calendar__tile--past,
    &.react-calendar__tile--now {
      abbr {
        background-color: transparent;
        color: color('pandaGrayDark');
      }
    }
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 1.2em 0.5em;
  }
}*/

.react-calendar__navigation {
  &__label {
    color: $black;
  }

  &__prev-button {
    transform: rotate(180deg);
  }

  &__arrow {
    &:focus {
      outline: 1px dotted;
    }

    [disabled] {
      opacity: 0.4;
    }
  }
}

.react-calendar__month-view__days__day:not([disabled]) {
  @include fontAtlasMedium();
}

.react-calendar .react-calendar__tile--active {
  background: transparent url('/images/assets/circle-outline-white.svg') no-repeat center;
  background-position-y: 1px;
  background-size: 44px 44px;
}

.react-calendar__tile:focus {
  outline: 1px dotted;
}

// Same structure as react calendar less
.react-calendar {
  width: 100%; // 350px;
  max-width: 100%;
  background: transparent; // white;
  border: none; // 1px solid rgb(160, 160, 150);
  @include fontBase(); // font-family: Arial, Helvetica, sans-serif;
  // line-height: 1.125em;

  &--doubleView {
    width: 100%; // 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -.5em;

      > * {
        width: 50%;
        margin: .5em;
      }
    }
  }

  &, & *, & *:before, & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;
    //outline-width: 0; // new

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    justify-content: center; //new
    height: 1.4375rem; // 44px;
    margin-bottom: 1.875rem; // 1em;

    button {
      flex-grow: initial !important;
      padding: 0 2rem; //new
      // flex-basis: 14.2857%; // new
      // min-width: 14.2857%; // 44px;
      background: none;
      overflow: hidden; // new

      &:enabled {
        &:hover, &:focus {
          background-color: transparent; // rgb(230, 230, 230);
        }
      }

      &[disabled] {
        // visibility: hidden; // new;
        // background-color: rgb(240, 240, 240);
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
       // text-transform: uppercase;
       // font-weight: bold;
      font-size: $font-size-sm; // .75em;
      @include fontAtlas(); // new

      &__weekday {
        padding: 0 0 0.625rem 0; // .5em;
      }
    }

    &__weekNumbers {
      font-weight: bold;

      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-sm; // .75em;
        padding: 0.8125rem 0; // calc(.75em / .75) calc(.5em / .75);
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: $body-color; // rgb(209, 0, 0);
        }

        &--neighboringMonth {
          color: $body-color; // rgb(209, 0, 0);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 1.4375rem; // 2em .5em;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.8125rem 0; // .75em .5em;
    background: none;
    font-size: $font-size-sm; // new

    &:disabled {
      background-color: transparent; // rgb(240, 240, 240);
      color: color('pandaGrayDark'); // New
    }

    &:enabled {
      &:hover, &:focus {
        background-color: color('pandagrayExtraLight'); // rgb(230, 230, 230);
      }
    }

    // Disabled so will inherit from other tile options
    // &--now {
    //   @bgcolor: lighten(rgb(220, 220, 0), 30%);
    //   background: @bgcolor;

    //   &:enabled {
    //     &:hover, &:focus {
    //       background: lighten(@bgcolor, 10%);
    //     }
    //   }
    // }

    &--hasActive {
      // @bgcolor: lighten(rgb(0, 110, 220), 30%);
      background: transparent; // @bgcolor;

      &:enabled {
        &:hover, &:focus {
          background: color('pandaGrayExtraLight'); // lighten(@bgcolor, 10%);
        }
      }
    }

    &--active {
      // @bgcolor: rgb(0, 110, 220);
      background: color('pandaGray'); // @bgcolor;
      color: $body-color; // white;

      // Diabled to inherit from tile
      // &:enabled {
      //   &:hover, &:focus {
      //     background: lighten(@bgcolor, 10%);
      //   }
      // }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        //background-color:
        background-color: color('pandaGray'); // rgb(230, 230, 230);
        color: $body-color; // white;
      }
    }
  }
}


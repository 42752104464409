// Time select
@import '../../styles/component.scss';

.label {
  composes: h6 d-block text-center from global;

  margin-bottom: $label-margin-bottom !important;
}

.select {
  width: 100%;
}

.dropdown button {
  padding-left: 1rem;
  text-align: left;
}


.share {
  position: relative;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  &:before {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent url('/images/assets/icon-share.svg') no-repeat center;
  }

  &:hover {
    text-decoration: none;
  }
}

.copied:before {
  filter: invert(51%) sepia(100%) saturate(7436%) hue-rotate(217deg) brightness(107%) contrast(102%);
}

.alert {
  opacity: 0;
  position: absolute;
  left: 50%;
  padding: 0 5px;
  white-space: nowrap;
  background-color: rgba(0, 97, 255, 0.8);
  border-radius: 4px;
  color: #FFF;
  font-size: 11px;
  transform: translateX(-50%) translateY(0);

  &.show {
    opacity: 1;
    transform: translateX(-50%) translateY(8px);
    transition: transform 0.2s ease, opacity 0.2s ease;
  }
}

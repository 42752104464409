@import '../../styles/component.scss';

.moduleContainerWrap {
  composes: container from global;
}

.moduleContainerRow {
  composes: row from global;
}

.cardCol {
  composes: col-24 col-xs-12 mb-4 mb-lg-0 from global;

  &:nth-child(n+4) {
    margin-top: $grid-gutter-width / 2;
  }

  .card {
    border: none;
  }
}

.odd {
  composes: col-sm-8 from global;
}

.sectionTitle {
  composes: section-header-text from global;
}

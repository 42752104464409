// // Tab component
// .nav-tabs {
//   .nav-link {
//     // margin-bottom: 0;
//     border-width: 0;
//     &:focus {
//       outline: none;
//     }
//     &:hover {
//       color: #000;
//       box-shadow: inset 0 -3px 0 0 color('shark');
//     }
//     &.active {
//       color: #000;
//       box-shadow: inset 0 -3px 0 0 #3f3f3f;
//     }
//   }
// }

.navbar-toggler {
  .main-nav-toggle-close {
    display: none;
  }

  &:not(.collapsed) {
    position: fixed;
    z-index: 100000;
    top: 1rem;
    right: 1rem;

    .main-nav-toggle-open {
      display: none;
      max-width: 20px;
    }

    .navbar-toggler-icon {
      background-image: none;
    }

    .main-nav-toggle-close {
      display: block;

      svg path {
        stroke: color('white');
      }
    }
  }
}

// Promo card
@import '../../styles/component.scss';

.cardWrap {
  height: 100%;
  z-index: 1;
  position: relative;

  > *,
  .card {
    height: inherit;
  }

  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
    z-index: 2;
  }
}

.card {
  composes: card from global;

  display: flex;
  align-items: stretch;
  border-color: transparent;
  box-shadow: inset 0 0 0 1px $cardBorderColor;
}

.cardBody {
  composes: card-body from global;
}

.cardTitleWrap {
  margin-bottom: 0.6875rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 1.25rem;
  }
}

.cardTitle {
  composes: card-title h4 mb-0 from global;

  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  hyphens: auto;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;

  @include media-breakpoint-down(sm) {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
  }

  &.multiLineThree {
    min-height: #{$h4-line-height * 2}em;

    @include media-breakpoint-down(sm) {
      min-height: #{$h5-line-height * 2}em;
    }
  }

  &.multiLineTwo {
    min-height: #{$h4-line-height * 2}em;

    @include media-breakpoint-down(sm) {
      min-height: #{$h5-line-height * 2}em;
    }
  }

  &.multiLineOne {
    @include media-breakpoint-only(xs) {
      min-height: #{$h5-line-height * 2}em;
    }

    @include media-breakpoint-only(md) {
      min-height: #{$h4-line-height * 2}em;
    }
  }
}

.cardText {
  color: color('shark');
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.cardFooter {
  composes: card-footer from global;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: $white;
  border: 0;
  box-shadow: inset 0 0 0 1px $cardBorderColor;
  text-align: center;
}

.cardLinkWrap {
  &,
  &:hover {
    color: $body-color;
    text-decoration: none;
  }

  &:hover {
    .cardFooter {
      background-color: color('pandaGrayExtraLight');
    }
  }

  &:active {
    .cardFooter {
      background-color: color('pandaGray');
    }
  }
}

.imageWrap {
  composes: image-rect image-rect-5-2 from global;

  svg {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

// Needs to override inline styles in Asset
.notSvg .imageWrap > div {
  max-width: 100% !important;
  max-height: 100% !important;

  > div {
    position: static !important;
  }
}

.clock {
  position: absolute;
  top: 12px;
  right: 15px;
  background-color: $white;
  border-radius: 50%;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 31px;
    height: 31px;
    background: transparent url(/images/assets/in-progress-clock.svg) center / contain no-repeat;
    z-index: 999999;
  }
}

button {
  &[type='submit'][disabled] {
    cursor: not-allowed;
    border: none;
  }

  &:hover {
    &:focus,
    &:focus-within {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.btn {
  min-height: 2.75rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-grey-bg {
  background-color: color('white');

  &,
  &:hover {
    border-color: color('black');
  }

  &:hover {
    background-color: color('pandaGrayExtraLight');
  }

  &:active {
    background-color: color('pandaGray');
    border-color: color('black');
  }

  &.disabled,
  &:disabled {
    opacity: 1;
    background-color: color('pandaGray');
    border-color: color('pandaGray');
    color: color('pandaGrayDark');
  }
}

.theme {
  @each $key, $value in $themes {
    &-#{$key} {
      $themePrimaryColor: map-get($value, primaryColor);
      $themeSecondaryColor: map-get($value, secondaryColor);
      $themeFontColor: map-get($value, fontColor);

      &.themeBgColor,
      .themeBgColor  {
        background-color: $themePrimaryColor;
        color: $themeFontColor;

        img {
          border: none !important;
        }
      }

      h1, .h1 {
        color: map-get($value, h1FontColor);
      }

      h2, .h2 {
        &:not(h1) {
          color: map-get($value, h2FontColor);
        }
      }

      .sectionTitleWrap {
        color: $themePrimaryColor;
      }

      .imageColorWrap {
        &:not(.image-rect) {
          border: 1.875rem solid $themePrimaryColor;
          background-color: $themePrimaryColor;
        }

        &.image-rect img {
          border: 1.875rem solid $themePrimaryColor;
          background-color: $themePrimaryColor;
        }

        img {
          border: none;
        }
      }

      .btn {
        &.btn-default {
          color: $themeFontColor;
          background-color: transparent;

          &:hover {
            color: $themeFontColor;
            background-color: invert($themeFontColor);
          }

          &:active {
            color: $themeFontColor;
            background-color: color('pandaGray');
          }

          &:focus {
            color: $themeFontColor;
            box-shadow: 0 0 0 $input-btn-focus-width color('pandaGray');
          }
        }
      }

      .progress {
        background-color: $themePrimaryColor;
      }

      // .btn.btn-solid,
      // .react-calendar .react-calendar__tiles abbr,
      // .react-calendar .react-calendar__month-view__days__day.react-calendar__tile--active abbr {
        .btn.btn-solid {
        color: invert($themeFontColor);
        background-color: $themeSecondaryColor !important;

        &:hover:not(.disabled):not(:disabled) {
          background-color: rgba($themeSecondaryColor, .9) !important;
        }

        &:active,
        &:focus {
          background-color: darken($themeSecondaryColor, 2%);
        }
      }
    }
  }

  &-default {
    &.moduleTopics {
      background-color: color('pandaGrayLight');

      .section-header-text {
        color: color('crimson')
      }

      .topicWrap {
        border-top-color: color('crimson');

        &:last-of-type {
          border-bottom-color: color('crimson');;
        }
      }
    }
  }

  &-video-library {
    &.moduleVideos {
      background-color: color('white');

      .section-header-text {
        color: color('sapphire')
      }
    }
  }
}

// Global header
@import '../../styles/component.scss';

.globalHeader {
  composes: container w-100 mw-100 from global;

  @include media-breakpoint-down(sm) {
    padding: 0 map-get($grid-gutter-widths, 'xs') !important;
  }
}

.secondaryNavItemMain {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.navbar {
  composes: px-0 py-3 position-relative from global;

  justify-content: flex-end !important;

  > * {
    order: 3;
  }
}

.logo {
  composes: navbar-brand mr-auto from global;

  max-width: 10rem;
  order: 1;
}

.logoImageWrap {
  composes: d-flex from global;
}

.mainNavCollapse {
  order: 2;

  @include media-breakpoint-down(sm) {
    align-items: flex-start !important;
    display: flex;
    order: 4;
    text-align: center;
    width: 100vw;
    left: 0;
    z-index: 100;
    background-color: color('canopy');
    top: 0px;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    position: fixed !important;
  }
}

.mainNavBar {
  composes: ml-md-auto mr-md-3 from global;

  @include media-breakpoint-down(sm) {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    background-color: color('canopy');
    width: 100%;
  }
}

.mainNavItem {
  composes: nav-item from global;
}

.mainNavLink {
  composes: nav-link from global;

  @include fontAtlas();
  font-size: $font-size-sm;
  line-height: 1.625rem;
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
  padding-left: 1.5625rem !important;
  padding-right: 1.5625rem !important;

  @include media-breakpoint-down(sm) {
    color: color('white') !important;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  &:hover {
    text-decoration: underline;
  }
}

.mainNavLinkTopLevel {
  @include media-breakpoint-down(sm) {
    @include sharpGroteskMedium22();

    font-size: $h5-font-size;
  }
}

.mainNavLinkHome {
  @include media-breakpoint-up(md) {
    text-decoration: underline !important;
  }
}

.mainNavLiHome {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.mainNavToggle {
  margin-left: 1rem;
}

.signInWrap {
  display: none;
  margin-left: 1.25rem;
  margin-right: 0;

  @include media-breakpoint-up(sm) {
    display: block;
  }
}

.btnSignin {
  composes: btn btn-sm btn-grey-bg from global;

  color: $body-color;
  background-color: transparent;
  white-space: nowrap;
  padding-top: 0.175rem;
  padding-bottom: 0.175rem;
  padding-left: $input-btn-padding-x !important;
  padding-right: $input-btn-padding-x !important;
  min-height: initial !important;
}

.userName {
  @include fontBaseReset();

  &::after {
    display: none;
  }

  + div {
    left: auto;
    right: 0;
  }
}

.searchBarWrap {
  @include media-breakpoint-down(sm) {
    &:focus-within {
      position: absolute;
      background-color: white;
      width: 100%;
      left: 0;
    }
  }
}

.signedIn {
  position: relative;

  .btnSignin {
    position: relative;
    max-width: 180px;
    border: 0;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
    }

    &:before {
      height: 100%;
      background: linear-gradient(to left, white, transparent 50%);
    }

    &:after {
      bottom: -16px;
      left: 0;
      height: 16px;
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;

      ~ .signinMenu {
        display: block;
      }
    }
  }

  .signinMenu:hover {
    display: block;
  }
}

.secondaryNavbarWrap {
  display: none;
  border-top: solid 1px color('pandaGrayMedium');
  border-bottom: solid 1px color('pandaGrayMedium');
  margin-left: -20px;
  margin-right: -20px;
  padding-top: $navbar-padding-y;
  padding-bottom: $navbar-padding-y;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.secondaryNavbarNav {
  composes: navbar-nav mx-auto from global;
}

.secondaryNavbar {
  composes: navbar navbar-expand-sm navbar-white from global;
}

.secondaryNavItem {
  composes: nav-item from global;

  @include sharpGroteskBook();

  padding-left: 3.75rem;
  padding-right: 3.75rem;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.secondaryNavLink {
  composes: px-0 from global;

  color: color('black') !important;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

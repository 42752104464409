@import '../../styles/component.scss';

.moduleContainerWrap {
  composes: container from global;
}

.moduleContainerRow {
  composes: row from global;
}

.videoCol {
  composes: col-4 col-md-8 from global;

  &:nth-child(n+4) {
    display: none;
    margin-top: $grid-gutter-width / 2;
  }
}

.viewMoreTextLinkWrap {
  text-align: center;
  margin-top: $grid-gutter-width;
}

.viewMoreTextLink {
  @include fontBase();
  @include sharpGroteskMedium();
  
  color: $body-color;
  text-decoration: underline;
  line-height: 1.5;
  text-align: center;
  margin-top: $grid-gutter-width;
}

.sectionTitle {
  composes: section-header-text from global;
}
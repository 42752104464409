// Calendar
@import '../../styles/component.scss';

.moduleCalendar {
  width: 100%;
  margin-bottom: 2.625rem;
}

.formGroup {
  composes: form-group from global;
}

.label {
  composes: h6 d-block text-center from global;

  margin-bottom: $label-margin-bottom !important;
}

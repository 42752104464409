// ModuleTextList

.module {
  ul,
  ol {
    list-style: none;
    margin: 0;
    padding-left: 18px;
  }

  li {
    margin-bottom: 2rem;

    > p {
      margin-left: 4px;
    }

    b {
      display: block;
      margin-bottom: 0.75rem;
    }
  }
}

.checkboxSquare ul {
  list-style-image: url('/images/assets/icon-checkbox-square.svg');
}

.checkboxCircle ul {
  list-style-image: url('/images/assets/check-mark-circled.svg');
}

/* Fonts */

@font-face {
  font-family: 'Sharp Grotesk DB Medium';
  src: url('/fonts/SharpGroteskDBMedium20.woff2') format('woff2'),
    url('/fonts/SharpGroteskDBMedium20.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Grotesk DB Medium 22';
  src: url('/fonts/SharpGroteskDBMedium22.woff2') format('woff2'),
    url('/fonts/SharpGroteskDBMedium22.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Grotesk DB Book';
  src: url('/fonts/SharpGroteskDBBook20.woff2') format('woff2'), url('/fonts/SharpGroteskDBBook20.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Atlas Grotesk';
  src: url('/fonts/AtlasGrotesk-Regular.woff2') format('woff2'), url('/fonts/AtlasGrotesk-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Atlas Grotesk Medium';
  src: url('/fonts/AtlasGrotesk-Medium.woff2') format('woff2'), url('/fonts/AtlasGrotesk-Medium.woff') format('woff');
  font-display: swap;
}

// Time select
@import '../../styles/component.scss';

.label {
  composes: h6 d-block text-center from global;
  margin-bottom: $label-margin-bottom !important;
}

.selectOptions {
  composes: select-options from global;
  list-style: none;
  padding: 0;
}

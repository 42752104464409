.socials {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;

  li:not(:first-child) {
    margin-left: 0.75rem;
  }

  a {
    display: block;
    width: 1.5rem;
    height: 1.5rem;

    &:before {
      content: '';
      display: block;
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .facebook:before {
    background: transparent url('/images/assets/icon-facebook.svg') no-repeat center;
  }

  .twitter:before {
    background: transparent url('/images/assets/X-Twitter.png') no-repeat center;
  }

  .linkedin:before {
    background: transparent url('/images/assets/icon-linkedin.svg') no-repeat center;
  }
}

/* ====================
Base / Text
===================== */

p {
 &:last-of-type {
  margin-bottom: 0;
 }

 a {
   &, &:hover {
     text-decoration: underline;
     color: inherit;
   }
 }
}

u {
  text-decoration: underline;
}

@include media-breakpoint-down(md) {
  h1, .h1 { font-size: $h2-font-size; line-height: $h2-line-height; }
  h2, .h2 { font-size: $h3-font-size; line-height: $h3-line-height; }
  // h3, .h3 { font-size: $h4-font-size; line-height: $h4-line-height; }
}

@include media-breakpoint-down(sm) {
  h1, .h1 { font-size: $h3-font-size; line-height: $h3-line-height; }
  h2, .h2 { font-size: $h4-font-size; line-height: $h4-line-height; }
  h3, .h3 { font-size: $h5-font-size; line-height: $h5-line-height; }
  h4, .h4 { font-size: $h6-font-size; line-height: $h6-line-height; }
  h5, .h5 { font-size: $h6-font-size; line-height: $h6-line-height; }
}

@include media-breakpoint-up(md) {
  h1, .h1 { font-size: $h1-font-size; line-height: $h1-line-height; }
  h2, .h2 { font-size: $h2-font-size; line-height: $h2-line-height; }
  h3, .h3 { font-size: $h3-font-size; line-height: $h3-line-height; }
  h4, .h4 { font-size: $h4-font-size; line-height: $h4-line-height; }
  h5, .h5 { font-size: $h5-font-size; line-height: $h5-line-height; }
  h6, .h6 { font-size: $h6-font-size; line-height: $h6-line-height; }
}

.section-header-text {
  @extend h2;
}

b, .b,
strong, .strong {
  @include fontAtlasMedium();
}

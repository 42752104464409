@import '../../styles/component.scss';


.moduleContainerWrap {
  composes: container bg-white from global;
}

.moduleContainerRow {
  composes: row from global;

  margin-top: 3.125rem;
}

.courseCol {
  composes: col-24 col-xs-12 col-md-8 from global;

  @include media-breakpoint-down(xxs) {
    margin-top: 3.125rem;
  }

  @include media-breakpoint-between(xs, sm) {
    &:nth-child(n+3) {
      margin-top: 3.125rem;
    }
  }

  @include media-breakpoint-only(md) {
    &:nth-child(n + 4) {
      display: none;
      margin-top: 3.125rem;
    }
  }

  @include media-breakpoint-up(md) {
    &:nth-child(n+4) {
      margin-top: 3.125rem;
    }
  }

  @include media-breakpoint-up(lg) {
    &:nth-child(n + 4) {
      display: none;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.sectionHeaderWrap {
  composes: col-24 col-xs-20 col-sm-18 col-md-16 col-lg-14 px-0 from global;
}

.sectionTitleWrap {
  margin-bottom: 0.625rem;

  .theme-self-guided-learning & {
    color: map-deep-get($themes, 'self-guided-learning', 'secondaryColor') !important;
  }
}

.sectionTitle {
  margin-bottom: 0;
}

.subtitleWrap {
  margin-bottom: 2.3125rem;
}

.showAll {
  .courseCol {
    display: block;
  }

  .viewMoreTextLinkWrap {
    display: none;
  }
}

.viewMoreTextLinkWrap {
  text-align: center;
  margin-top: 3.125rem;
}

.viewMoreTextLink {
  @include fontLinkColorReset();

  composes: h5 from global;

  text-decoration: underline;
  line-height: 1.5;
  text-align: center;
  margin-top: 3.125rem;
}

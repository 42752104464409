@import '../../styles/component.scss';

.alertBarWrap {
  composes: container-md-down container-fluid text-center position-relative py-2 from global;

  background-color: $cardBorderColor;
}

.alertBarWrapHidden {
  display: none;
}

.alertBarCta {
  @include fontBaseReset();

  margin-left: 0.5rem;

  &,
  &:hover {
    text-decoration: underline;
  }
}

.alertBarBody {
  @include media-breakpoint-down(sm) {
    font-size: $font-size-sm;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.alertBarWrap,
.alertBarCta {
  color: color('black');
  font-size: $font-size-sm;
}

.closeButton {
  composes: close from global;

  @include center-vertical();

  font-size: $font-size-sm;
  opacity: 1;
  right: 1rem;

  svg path {
    stroke: color('black');
  }
}

@import '../../styles/component.scss';

.moduleContainerWrap {
  composes: container from global;
}

.moduleContainerRow {
  composes: row from global;
}

.titleWrap {
  margin-bottom: 0.625rem;
}

.title {
  composes: mb-0 from global;
}

.mediaCol {
  composes: col-12 offset-6 col-xs-10 offset-xs-0 col-md-10 from global;
}

.textCol {
  composes: col-24 col-xs-14 from global;
}

.ctaButtonWrap {
  margin-top: 1.25rem;
}

.ctaButton {
  composes: btn btn-default from global;

  border-width: 1px;
}

.imageWrap {
  composes: image-rect image-rect-4-3 mb-5 mb-sm-0 from global;
  composes: imageColorWrap from '../Asset/Asset.module.scss';
}

.moduleContainerRow .mediaCol svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@import '../../styles/component.scss';

.pageCourseTopic {
  composes: section container mt-2 from global;
}

.layout {
  composes: container d-flex justify-content-center from global;

  position: relative;
  color: $color__core__primary;

  @media (min-width: 1140px) {
    gap: 50px;
  }

  article {
    max-width: 590px;

    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }

    @include media-breakpoint-up(md) {
      width: 70%;
      margin-right: calc(50vw - 590px);
    }
  }
}

.sideNav {
  position: relative;
  width: 100%;
  max-width: 295px;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  @media (max-width: 1140px) {
    margin-left: -100px;
  }

  &.noToc {
    @include media-breakpoint-down(md) {
      display: none;
      margin: 0;
    }
  }

  nav {
    position: fixed;
    z-index: 2;
    top: auto;
    left: auto;
    width: 100%;
    max-width: 295px;
    padding: 2px 49px 34px 0;
    white-space: nowrap;
    background-color: $white;
    transform: translateY(0);
    transition: transform 0.4s linear 0;

    @media (min-width: 1140px) {
      padding: 2px 49px 34px;
    }

    &.scrolling {
      transform: translateY(-120px);
      transition: transform 0.4s linear 0.1s;
    }

    &.footerInView {
      position: absolute;
      bottom: 3.5rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin-bottom: 0.5rem;
      overflow: hidden;
      color: rgba(82, 74, 62, 0.4);
      text-overflow: ellipsis;
      line-height: 1.25rem;

      &.active a {
        color: $color__core__primary;
      }
    }

    a {
      color: rgba(82, 74, 62, 0.4);
      font-size: $font-size-xs;
      text-decoration: none;
      transition: color 0.12s ease;

      &:hover {
        color: $color__core__primary;
        transition: color 0.12s ease;
      }
    }
  }
}

.eyebrow {
  padding-bottom: 1rem;
  @include fontAtlasMedium();
  line-height: 1.5rem;
}

.name {
  margin-bottom: 1rem;
  @include sharpGroteskBook();
  font-size: $h3-font-size;
  line-height: 2.4rem;
}

.readingTime {
  margin-bottom: 0;

  &.hasDate {
    margin-left: 0.5rem;

    &::before {
      content: '\2022';
      padding-right: 0.5rem;
    }
  }
}

.description {
  @include fontBaseReset();
}

.contentSection {
  section[class="section"] {
    margin: 0 0 2rem;
  }

  p {
    margin-bottom: 1.125rem;
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  h2,
  .h2 {
    margin-bottom: 1.125rem;
    @include sharpGroteskBook();
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  h3,
  .h3 {
    @include sharpGroteskBook();
    font-size: $h5-font-size;
    line-height: $h5-line-height;
    margin-bottom: 1rem; // SUPPORT-1734
  }

  h4,
  .h4 {
    @include sharpGroteskBook();
    font-size: $h6-font-size;
    line-height: $h6-line-height;
  }

  h5,
  .h5 {
    @include sharpGroteskBook();
    font-size: $h6-font-size;
    line-height: $h6-line-height;
  }

  @include media-breakpoint-down(sm) {
    h2,
    .h2 {
      font-size: $h4-font-size;
      line-height: $h4-line-height;
    }

    h3,
    .h3 {
      font-size: $h5-font-size;
      line-height: $h5-line-height;
    }

    h4,
    .h4,
    [class*='titleTextSizeMedium'] {
      font-size: $h4-font-size;
      line-height: $h4-line-height;
    }

    h5,
    .h5 {
      font-size: $h6-font-size;
      line-height: $h6-line-height;
    }
  }

  ol,
  ul {
    padding-left: 1.15rem;
  }

  section {
    h2,
    .h2,
    h3,
    .h3 {
      color: $color__core__primary !important;
    }
  }

  [class*='ModuleTextMedia_textCol'],
  [class*='ModuleTextMedia_mediaCol'] {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 1rem 0;
  }

  // Target last p tag in ModuleTextMedia-body, not general li p
  [class*='ModuleTextMedia_textCol'] div div > p:last-child {
    margin-bottom: 0;
  }
}

.signInModal {
  position: relative;
}

.signInPromptModalHeader {
  width: 100%;
  border-bottom: none;
  position: absolute;
  z-index: 2;
  padding: 2rem 2rem 0 2rem;

  button {
    background-image: url('/images/assets/icon-close-x.svg');
    background-repeat: no-repeat;
    width: 0.6875rem;
    display: inline-block;
    height: 0.6875rem;
    background-position: center;
    padding: 0 !important;
    margin: 0 0 0 auto !important;

    span {
      display: none;
    }
  }
}

.signInPromptModalBody {
  composes: container w-100 d-flex align-items-center justify-content-center flex-column from global;

  min-height: 50vh;
}

.signInPromptWrap {
  max-width: 22rem;
}

.signInPromptHeaderText {
  composes: h4 from global;

  margin-bottom: 1.625rem;
}

.signInPromptCtaWrap {
  composes: text-center from global;
}

.signInPromptCta {
  composes: btn btn-sm btn-outline-dark from global;
}

@import '../../styles/component.scss';

.pageCourseFinished {
  position: fixed;
  top: 5.125rem;
  max-width: 100vw;
  min-width: 18.75rem;
  overflow: hidden;
  right: -30vw;
  width: calc(100vw - 2rem);
  transition-delay: 1ms;
  transition: all 1s ease;
  transform: translate(100%, 0);
  z-index: 0;

  @include media-breakpoint-up(sm) {
    max-width: 26vw;
    height: calc(100vh - 5.125rem);
  }

  @include media-breakpoint-up (lg) {
    max-width: 30vw;
  }

  @media only screen
    and (min-device-width: 375px)
    and (max-device-width: 820px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: landscape) {
      top: 0;
      height: 100vh;

      .containerWrap {
        padding: 2rem;
      }
  }

  .headerText {
    margin-bottom: 1.2rem;
    font-size: 2.5rem;

    @include media-breakpoint-up(lg) {
      font-size: 4.0625rem;
    }
  }

  @media (max-height: 840px) {
    max-width: 25vw;

    .headerText {
      font-size: 2.5rem;
    }
  }
}

.containerWrap {
  composes: themeBgColor from global;
  padding: 4.0625rem 3.5625rem;
  height: 100%;

  @media (max-height: 840px) {
    padding: 2rem;
  }
}

.imageOuterWrap {
  max-width: 16.3rem;
  margin-bottom: 1rem;

  svg,
  img {
    @include media-breakpoint-down(sm) {
      max-height: 25vh;
    }

    @media (max-height: 840px) {
      max-width: 15vh;
      max-height: 25vh;
      min-width: 60px;
      min-height: 100px;
    }

    @media (max-height: 460px) {
      display: none;
    }
  }
}

.pageCourseFinishedHide {
  transform: translate(100%, 0);
}

.pageCourseFinishedShow {
  transform: translate(0, 0);
  transition-delay: 1000ms;
  transform-origin: top right;
  right: 0;
  z-index: 1000;
}

.headerText {
  composes: h1 from global;
}

.bodyText {
  @include sharpGroteskMedium();

  margin-bottom: 1rem;
}

.ctaWrap {
  margin-top: 1rem;
}

.cta {
  composes: btn btn-light from global;
}

.closeButton {
  composes: close from global;

  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: $font-size-sm;
  opacity: 1;

  svg {
    width: 1.125rem;
    height: 1.125rem;

    path {
      stroke: color('black');
      stroke-width: 0.08rem;
    }
  }
}

.imageOuterWrap {
  position: relative;
  margin-bottom: 1rem;
}

.moduleCarouselSlide {
  user-select: text; // Ensure text can be selected
  margin-left: 3rem;
  margin-right: 3rem;
}

.slick-slider{
  user-select: text !important; 
}
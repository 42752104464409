/* ====================
  Base / HTML Elements
  ===================== */
.wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > .main-wrap {
    flex-grow: 1;

    main {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}

@include media-breakpoint-down(sm) {
  .row {
    margin: 0 -#{map-get($grid-gutter-widths, 'xs')};

    [class*='col-'] {
      padding-left: map-get($grid-gutter-widths, 'xs');
      padding-right: map-get($grid-gutter-widths, 'xs');
    }
  }
}

@include media-breakpoint-up(sm) {
  .row {
    margin: 0 -#{map-get($grid-gutter-widths, 'sm')};

    [class*='col-'] {
      padding-left: map-get($grid-gutter-widths, 'sm');
      padding-right: map-get($grid-gutter-widths, 'sm');
    }
  }
}

@include media-breakpoint-up(md) {
  .row {
    margin: 0 -#{map-get($grid-gutter-widths, 'md')};

    [class*='col-'] {
      padding-left: map-get($grid-gutter-widths, 'md');
      padding-right: map-get($grid-gutter-widths, 'md');
    }
  }
}

@include media-breakpoint-up(lg) {
  .row {
    margin: 0 -#{map-get($grid-gutter-widths, 'lg')};

    [class*='col-'] {
      padding-left: map-get($grid-gutter-widths, 'lg');
      padding-right: map-get($grid-gutter-widths, 'lg');
    }
  }
}

.container {
  padding: 0 map-get($grid-gutter-widths, 'xs') * 2;

  @include media-breakpoint-up(sm) {
    width: 83.3333vw;
    max-width: 100%;
    padding: 0 map-get($grid-gutter-widths, 'sm') / 2;
  }

  @include media-breakpoint-up(md) {
    padding: 0 map-get($grid-gutter-widths, 'md') / 2;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 map-get($grid-gutter-widths, 'lg') / 2;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.container-fluid {
  &.container-md {
    max-width: map-get($container-max-widths, 'md');
  }

  &.container-lg {
    max-width: map-get($container-max-widths, 'lg');
  }

  &.container-xl {
    max-width: map-get($container-max-widths, 'xl');
  }
}

.section {
  margin-top: map-deep-get($section-margins, 'default', 'top');
  margin-bottom: map-deep-get($section-margins, 'default', 'bottom');

  @include media-breakpoint-up(xs) {
    margin-top: map-deep-get($section-margins, 'xs', 'top');
    margin-bottom: map-deep-get($section-margins, 'xs', 'bottom');
  }

  @include media-breakpoint-up(lg) {
    margin-top: map-deep-get($section-margins, 'lg', 'top');
    margin-bottom: map-deep-get($section-margins, 'lg', 'bottom');
  }

  &[class*='theme-'] {
    padding-top: map-deep-get($section-margins, 'default', 'top');
    padding-bottom: map-deep-get($section-margins, 'default', 'bottom');
    margin-top: 0;
    margin-bottom: 0;

    @include media-breakpoint-up(xs) {
      padding-top: map-deep-get($section-margins, 'xs', 'top');
      padding-bottom: map-deep-get($section-margins, 'xs', 'bottom');
    }

    @include media-breakpoint-up(lg) {
      padding-top: map-deep-get($section-margins, 'lg', 'top');
      padding-bottom: map-deep-get($section-margins, 'lg', 'bottom');
    }
  }
}

.sub-section {
  margin-top: map-deep-get($sub-section-margins, 'default', 'top');
  margin-bottom: map-deep-get($sub-section-margins, 'default', 'bottom');

  &[class*='theme-'] {
    padding-top: map-deep-get($sub-section-margins, 'default', 'top');
    padding-bottom: map-deep-get($sub-section-margins, 'default', 'bottom');
    margin-top: 0;
    margin-bottom: 0;
  }
}

.slide-down {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(0, -100%);
  transition: transform 0.5s ease-in-out 0.1s;

  .header-visible & {
    transform: translate(0, -100%);
  }

  .header-hidden & {
    transform: translate(0, 0);
  }
}

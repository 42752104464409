// Promo card
@import '../../../styles/component.scss';

.card {
  composes: card from global;

  display: flex;
  align-items: stretch;

  &:hover {
    .cardFooter {
      background-color: #EEE;
    }
  }

  * {
    &, &:hover {
      color: $body-color;
      text-decoration: none;
    }
  }

  a, a:hover {
    text-decoration: underline;
  }
}

.cardBody {
  composes: card-body from global;

  padding-left: 0;
  padding-right: 0;
}

.cardTitle {
  composes: card-title h4 from global;

  margin-bottom: 1rem;
}

.cardText {
  color: color('shark');

  p {
    &:last-of-type {
      margin-bottom: revert;
    }

    &:not(:first-of-type):last-of-type {
      margin-bottom: 0;
    }
  }
}

.cardFooter {
  composes: card-footer from global;

  background-color: #FFF;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.cardLinkWrap {
  &:hover {
    text-decoration: none;
  }
}

.imageWrap {
  composes: imageColorWrap from global;
}

@import '../../styles/component.scss';

.imgFluid {
  composes: img-fluid from global;
}

.imageColorWrap {
  composes: imageColorWrap from global;
}

.imageCircle {
  > div {
    position: relative;
    width: 100%;
    height: 0;
    padding: 100% 0 0;
    border-radius: 50%;
    overflow: hidden;

    > div {
      position: static !important;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

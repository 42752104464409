.image-rect {
  &.inline-svg,
  > div {
    position: relative;
    width: 100%;
    height: 0;
    padding: 56.25% 0 0; // 16:9
    overflow: hidden;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }

  &-4-3 {
    &.inline-svg,
    > div {
      padding: 75% 0 0;
    }
  }

  &-5-2 {
    &.inline-svg,
    > div {
      padding: 40% 0 0;
    }
  }
}

.imageColorWrap {
  text-align: center;

  &:not(.image-rect) {
    background-color: color('lightblue');
    border: 1.875rem solid color('lightblue');
  }

  &.image-rect > div img {
    background-color: color('lightblue');
    border: 1.875rem solid color('lightblue');
  }
}

@import '../../styles/component.scss';

.mainWrap {
  background-color: $color__theme__coconut__300;
}

.pageContent {
  composes: section container from global;
  margin-top: 72px;
  color: $color__core__primary;
}

.sectionTitle {
  composes: h3 from global;

  margin-bottom: 60px;
  @include sharpGroteskBook();
  font-weight: 400;
  text-align: center;
}

.videoTitle {
  max-width: 495px;
  margin: 0 auto;
  padding: 24px 48px;
  @include fontAtlas();
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
  text-align: center;
}

.videoSummary {
  max-width: 495px;
  margin: 0 auto;
  padding: 24px 48px;
  @include fontAtlas();
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
}

.transcript {
  max-width: 590px;
  margin: 16px auto 48px;
  padding: 48px 0;

  h3 {
    margin-bottom: 16px;
    color: $black;
    @include sharpGroteskBook();
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
  }

  p {
    font-size: 14px;
    line-height: 1.6;
  }
}

.transcriptWrap {
  max-height: 200px;
  margin-bottom: 8px;
  overflow: hidden;
  transition: max-height .3s ease;

  &.open {
    max-height: 2000px;
    margin-bottom: 0;
    transition: max-height 1s ease;
  }
}

.readMore {
  margin-top: 16px;
  color: $color__core__primary;
  font-size: 14px;
  line-height: 1.6;
  text-decoration: underline;
}

.helpSection {
  h2,
  h3 {
    margin-bottom: 4.4rem;
    @include sharpGroteskBook();
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;

    @include media-breakpoint-up(sm) {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 5rem;
    padding: 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-evenly;
    }

    li {
      @include sharpGroteskBook();
      font-size: 1.25rem;
      line-height: 1.65rem;
      font-weight: 600;

      @include media-breakpoint-up(sm) {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 400;
      }

      section {
        margin: 0;
      }

      &:nth-child(1) {
        a:before {
          background: transparent url('/images/assets/icon-community.svg') no-repeat center;
        }
      }

      &:nth-child(2) {
        a:before {
          background: transparent url('/images/assets/X-Twitter.png') no-repeat center;
        }
      }

      &:nth-child(3) {
        a:before {
          background: transparent url('/images/assets/icon-contact.svg') no-repeat center;
        }
      }
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
    color: $color__core__primary;

    &:before {
      content: '';
      display: inline-block;
      width: 1.5rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }
  }
}
.backButton{
  
margin-top: 80px;
margin-left: 60px;
 

}
.nextButton{
  margin-top: 80px;
margin-right: 60px;
}
.Buttons{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 700px) {
  .backButton,
  .nextButton {
    margin-top: 20px; /* Reduce top margin for smaller screens */
    margin-left: 10px; /* Adjust left/right margins for spacing */
    margin-right: 10px;
  }
  .Buttons{
    justify-content: center;
  }
}
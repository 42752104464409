@import '../../styles/component.scss';

.moduleContainerWrap {
  composes: container from global;
}

.moduleContainerRow {
  composes: row from global;
}

.textCol,
.mediaCol {
  composes: col-24 col-md-12 from global;
}

.mediaAlignLeft {
  .textCol {
    margin-bottom: 1.875rem;
  }

  @include media-breakpoint-up(md) {
    .textCol {
      order: 2;
      margin-bottom: 0;
    }

    .mediaCol {
      order: 1;
    }
  }
}

.mediaAlignCentered {
  .textCol {
    order: 1;
    margin-bottom: 1.875rem;

    @include media-breakpoint-up(md) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .mediaCol {
    order: 2;
    margin-bottom: 1.875rem;

    @include media-breakpoint-up(md) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

.titleTextSizeSmall {
  composes: h5 from global;
}

.titleTextSizeMedium {
  composes: h4 from global;

  margin-bottom: 1.25rem;
}

.titleTextSizeLarge {
  composes: h3 from global;
  font-size: $h3-font-size !important;
  line-height: $h3-line-height !important;
  margin-bottom: 2rem !important;
}

// Promo card
@import '../../styles/component.scss';

/* https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css */

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  user-select: none;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  .slick-list,
  .slick-track {
    transform: translate3d(0, 0, 0);
  }
}

.slick-list {
  margin: 0;
  padding: 0;
  overflow: hidden;

  &:focus {
    outline: 0;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-track {
  top: 0;
  left: 0;

  &:after,
  &:before {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }
  &.dragging img {
    pointer-events: none;
  }
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}

.slick-dots {
  list-style: none;
  text-align: right;
  bottom: -2rem;
  width: 100%;
  margin: 0;

  li {
    position: relative;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0.25rem;
    padding: 0;
    cursor: pointer;

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.25rem;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: 0;
      background: 0;

      &:focus,
      &:hover {
        outline: 0;
      }

      &:focus:before,
      &:hover:before {
        opacity: 1;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        text-align: center;
        opacity: 0.25;
        background-color: color('black');
        border: .33rem solid color('black');
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.slick-active button:before {
      opacity: 0.75;
    }
  }
}

.slick-loading .slick-list {
  // background: url(/newsite/images/ajax-loader.gif) center center no-repeat $white;
}

.slick-arrow.slick-disabled {
  cursor: not-allowed;
  opacity: 0.8;
  background-color: #ccc;
}

.slick-dotted.slick-slider {
  margin-bottom: 2rem;
}

.slick-arrow {
  &,
  &:focus,
  &:focus-within {
    @include buttonReset();
  }

  z-index: 1;
  top: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 0 !important;
  transform: translateY(-50%);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.875rem;
    height: 1.4rem;
    background: transparent url('/images/assets/icon-chevron-right.svg') center / contain no-repeat;
    filter: brightness(0);
    transform: translate(-50%, -50%);
  }
}

.slick-next {
  right: 0;
}

.slick-prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}

[dir='rtl'] {
  .slick-next {
    right: auto;
    left: 1.5rem;
  }

  .slick-prev {
    right: 1.5rem;
    left: auto;
  }
}

@include media-breakpoint-up(md) {
  .slick-prev {
    left: -2.5rem;
  }

  .slick-next {
    right: -2.5rem;
  }

  .slick-arrow {
    width: 2rem;
    height: 2rem;

    &:not(.slick-disabled):hover {
      &:before {
        border-color: rgba(color('black'), 0.6);
        transition: border-color 0.25s linear;
      }
    }
  }
}

.simple .slick-arrow {
  border: 0;
  background-color: transparent;

  &:before {
    width: 1.5rem;
    height: 1.5rem;
    border-color: $white;
    border-width: 4px 4px 0 0;
  }
}

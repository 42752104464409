// Global footer
@import '../../styles/component.scss';

.footer {
  composes: section theme-dark themeBgColor px-0 from global;

  z-index: 111;
  padding-top: 6.25rem;

  @include media-breakpoint-up(lg) {
    padding-top: 9.375rem;
    padding-bottom: 3.125rem;
  }

  * {
    color: color('white')
  }
}

.footerContainer {
  composes: container from global;
}

.menu,
.menuColumnList {
  composes: list-unstyled from global;
}

.menuColumnList {
  padding: 0;
}

.menu {
  composes: row from global;
}

.menuWrap {
  margin-bottom: 6.25rem;

}

.menuColumn {
  composes: col-12 col-md from global;

  margin-bottom: 3.125rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;

    &:first-of-type {
      margin-left: 4.16667%;
    }
  }
}

.menuColumnListItem {
  font-size: $font-size-sm;
  margin-top: 0.625rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.menuColumnHeader {
  @include sharpGroteskMedium();

  margin-bottom: 1.25rem;
}

.hrSplit{
  composes: col-4 px-0 d-inline-block from global;

  border-top-color: color('stone');
  margin-bottom: 3.125rem;
}

@import '../../styles/component.scss';

.topicLandingContainer {
  composes: container from global;
}

.topicLandingInnerContainer {
  composes: col-24 offset-md-4 col-md-16 from global;
}

.moduleContainerWrap {
  composes: container from global;
}

.moduleContainerRow {
  composes: row from global;

  @include media-breakpoint-down(sm) {
    position: relative;
    padding-bottom: 5rem;
  }
}

.moduleContainerRowLanding {
  position: relative;
}

.sectionTitleWrap {
  margin-bottom: 1.25rem;
}

.sectionBodyWrap {
  margin-bottom: 3.125rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.25rem;
  }
}

.sectionTitle {
  composes: mb-0 section-header-text from global;
}

.sectionTitleLanding {
  composes: mb-0 from global;
}

.topicList {
  .topicWrap {
    border-top: solid 1px color('crimson');
    padding: $grid-gutter-width 0;
    margin-bottom: 0;

    &:last-of-type {
      border-bottom: solid 1px color('crimson');
    }
  }
}

.topicName {
  composes: h6 mb-0 pr-4 position-relative d-block from global;

  &::after {
    position: absolute;
    top: 0.25em;
    right: 0.25em;
    cursor: pointer;
    content: url(/images/assets/right-arrow.svg);
    transform: translate3d(0px, 0px, 0px);
    transform-style: preserve-3d;
    transition: transform 0.2s ease;
  }

  &:hover {
    color: $body-color;

    &::after {
      transform: translate3d(5px, 0px, 0px);
    }
  }
}

.sectionCol {
  composes: col-24 col-md-7 position-static position-md-relative from global;
}

.sectionRow {
  margin-bottom: 2rem;
}

.topicListCol {
  composes: col-24 offset-md-2 col-md-15 from global;
}

.viewMoreTextLinkWrap,
.viewMoreTextLinkWrapLanding {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  margin-top: 3.125rem;

  @include media-breakpoint-up(md) {
    margin-top: 1.25rem;
  }
}

.viewMoreTextLinkWrap {
  left: 0;

  @include media-breakpoint-up(md) {
    text-align: left;
    position: static;
  }
}

.viewMoreTextLink {
  @include fontBase();
  @include sharpGroteskMedium();

  color: $body-color !important;
  text-decoration: underline;
  line-height: 1.5;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
}

.moduleTopics {
  background-color: color('white');

  &:not(:first-of-type) {
    margin-top: 0;
    padding-top: 0;
  }

  &:nth-of-type(n + 4) {
    display: none;
  }

  .viewMoreTextLinkWrapLanding {
    display: none;
  }

  & + &:nth-of-type(3),
  main &:last-of-type {
    .moduleContainerRowLanding {
      padding-bottom: 3rem;
    }

    .viewMoreTextLinkWrapLanding {
      display: block;
    }
  }

  &.showAll {
    &,
    & ~ .moduleTopics {
      display: block !important;

      .moduleContainerRowLanding {
        padding-bottom: 0 !important;
      }

      .viewMoreTextLinkWrapLanding {
        display: none !important;
      }
    }
  }
}

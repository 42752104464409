// Promo card
@import '../../../styles/component.scss';

.card {
  composes: card from global;

  display: flex;
  align-items: stretch;
  height: 100%;
  max-width: 600px;
  padding: 0.75rem 0.75rem 0;
  background-color: $color__theme__coconut__300;
  border: none;
  color: $color__core__primary;
  transition: transform .25s ease-in-out;

  @include media-breakpoint-up(md) {
    padding: 0 0.75rem 0.75rem;
    background-color: $color__theme__coconut__400;
  }

  &:hover {
    background-color: $color__theme__coconut__400;

    @include media-breakpoint-up(md) {
      transform: scale(1.03);

      .cardCta {
        color: $black;
        border-bottom-color: $colorShark;
        color: $color__core__primary;
        text-decoration: none;
      }
    }
  }

  [class*='imageExpand'] {
    bottom: 30px;
  }
}

.cardWrap > a:hover {
  text-decoration: none;
}

.cardBody {
  composes: card-body from global;

  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  border: none;
}

.cardTitle {
  composes: card-title h5 from global;

  margin-bottom: 1.5rem;
  @include fontAtlas();
  font-weight: 600;
  line-height: 20px;
  border: none;
}

.cardText {
  margin-bottom: 48px;
  line-height: 24px;
  border: none;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.thumbWrap {
  position: relative;
  aspect-ratio: 16/9;
  margin-bottom: 1rem;
  overflow: hidden;

  &.isSvg [class*='inline-svg'] {
    display: flex;
    height: 100%;
  }
}

.imageWrap {
  & > div {
    max-width: 100% !important;
    max-height: 100% !important;
    margin: 0 !important;
  }

  & > svg {
    width: 100%;
    transform: scale(1.35);
  }
}

.category {
  display: inline-block;
  margin-right: 0.5rem;
  padding: 1px 5px;
  cursor: pointer;
  background-color: $color__opacity--2;
  border-radius: 50px;
  color: $color__core__primary;
  @include fontAtlas();
  font-size: 12px;
  line-height: 1.25;
  user-select: none;
}

.cardCta {
  position: relative;
  display: inline-block;
  width: max-content;
  margin-top: auto;
  border-bottom: 1px solid $black-opacity-15;
  color: $color__core__primary;
  font-weight: 500;
  line-height: 1.5;

  &::after {
    content: "";
    position: absolute;
    top: 6px;
    right: -24px;
    width: 14px;
    height: 14px;
    background: url('/images/assets/icon-open-link.svg') no-repeat;
  }
}

@import '../../styles/component.scss';

.moduleContainerWrap {
  composes: section container from global;
  user-select: text;

  @include media-breakpoint-down(sm) {
    width: calc(100vw - 4rem);
  }
}

.slick-slide {
  user-select: text !important; // Allow selection for specific text elements
}

.moduleCarousel {
  user-select: text; // Ensure text can be selected
}

// ImageExpand
.imageExpand {
  z-index: 11;
  position: absolute;
  bottom: 8px;
  right: 8px;
  border-radius: 50%;
  transition: 0.15s background-color linear;

  &:hover {
    background-color: #FFF;
    transition: 0.15s background-color ease-out;
  }

  &.close {
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
    bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center;

    svg {
      margin-top: -3px;
    }
  }
}

.assetWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  max-height: 540px;
  min-height: 340px;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    > div {
      width: 100%;
    }
  }
}

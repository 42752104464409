@import '../../styles/component.scss';

.moduleHero {
  composes: section p-0 from global;
}

.moduleContainerWrap {
  composes: container from global;
}

.moduleContainerRow {
  composes: row from global;

  display: flex;
  padding-top: map-deep-get($section-margins, 'default', 'top');
  padding-bottom: map-deep-get($section-margins, 'default', 'bottom');

  @include media-breakpoint-up(xs) {
    min-height: 404px;
  }
}

.textCol {
  composes: col-24 col-xs-12 col-md-11 from global;

  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @include media-breakpoint-down(xxs) {
    margin-bottom: map-deep-get($section-margins, 'default', 'bottom')
  }
}

.imageCol {
  composes: col-16 offset-4 col-xs-10 offset-xs-2 offset-md-3 col-lg-9 offset-lg-4 from global;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .theme-home & {
    justify-content: flex-end;

    margin-bottom: -#{map-deep-get($section-margins, 'default', 'bottom')};
  }
}

.headerText {
  margin-bottom: 0.875rem;
}

.heroImage {
  > :global(.inline-svg) {
    display: flex;
    justify-content: center;
  }
}

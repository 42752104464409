body {
  width: 100%;
  overflow-x: hidden;

  &.modal-open {
    overflow: auto !important;
  }
}

abbr {
  &[title],
  &[data-original-title] {
    text-decoration: none;
  }
}

ol ol,
ol ul {
  list-style: disc;
}

*,
*::before,
*::after {
  scroll-margin-top: 100px;
}
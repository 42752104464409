@import '../../styles/component.scss';

.custom-select {
  height: 2.75rem;
  padding: 0 1rem;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius-sm;
  color: color('shark');
  font-size: $input-font-size-sm;
  line-height: $input-line-height-sm;
  text-align: left;
}

.select-options {
  composes: list-unstyled bg-white w-100 from global;

  outline: none;

  &.menu-open {
    list-style: none;
    padding: 0;
    box-shadow: none;
  }

  li {
    cursor: pointer;
    padding: 0 1rem;
    border: solid $input-border-color;
    border-width: 0 1px 1px;
    color: color('shark');
    font-size: $input-font-size-sm;
    line-height: 2.25rem;
    text-align: left;

    &:hover {
      background-color: color('pandaGrayLight');
    }
  }
}

input {
  &:hover {
    &:focus,
    &:focus-within {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

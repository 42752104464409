$color__core__black__primary: #1e1919;
$color__core__white: #fafafa;
$color__theme__link: #0061ff;
$color__theme__primary--dark: #0057e5;
$color__disabled__background: #bfbfbf;

$color__standard__text: #000;
$color__attention__text: #0061fe;

$color__utility__focusring: #72a2f1;

$color__standard__border: rgba(0, 0, 0, 0.15);

$breakpoint__mobile__large: 480px;
$breakpoint__tablet__standard: 768px;
$breakpoint__tablet__large: 1024px;
$breakpoint__desktop__standard: 1280px;

$motion__ease--out: cubic-bezier(0.5, 0, 0, 1);

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/******************************************
* Global Evidon Link Styles
******************************************/
#_evidon-message,
.evidon-prefdiag-message,
#prc-message {
    a:link,
    a:visited {
        color: $color__theme__link !important;

        &:hover {
            text-decoration: none !important;
        }

        &:focus {
            outline: none;
        }

        &:focus-visible {
            box-shadow: 0 0 0 3px $color__utility__focusring;
        }
    }
}

/******************************************
* Banner Notice Styles
******************************************/
#_evidon_banner {
    display: flex !important;
    padding: 24px !important;
    flex-flow: column nowrap !important;
    animation: slide-up 0.3s $motion__ease--out;

    @media screen and (min-width: $breakpoint__mobile__large) {
        flex-flow: row wrap !important;
        justify-content: flex-end !important;
    }

    @media screen and (min-width: $breakpoint__tablet__standard) {
        flex-flow: row nowrap !important;
        padding: 16px 48px !important;
    }

    @media screen and (min-width: $breakpoint__tablet__large) {
        padding: 24px 48px !important;
    }

    button {
        width: 100% !important;
        align-items: center !important;
        white-space: nowrap;

        @media screen and (min-width: $breakpoint__mobile__large) {
            width: unset !important;
        }
    }

    &.animate-out {
        animation: slide-up 0.3s $motion__ease--out reverse;
    }
}

#_evidon-message {
    margin-right: 0 !important;
    margin-bottom: 16px;

    a:link,
    a:visited {
        color: $color__theme__link !important;

        &:hover {
            text-decoration: none !important;
        }

        &:focus {
            outline: none;
        }

        &:focus-visible {
            box-shadow: 0 0 0 3px $color__utility__focusring;
        }
    }

    @media screen and (min-width: $breakpoint__tablet__standard) {
        margin-right: 48px !important;
        margin-bottom: 0 !important;
    }
}

#_evidon_banner,
#evidon-prefdiag-tabfooter {
    button {
        line-height: 44px !important;
        padding: 0 20px !important;
        margin: 0 !important;
        border: none !important;
        box-shadow: inset 0 0 0 1px $color__core__black__primary;
        color: $color__core__black__primary !important;

        &:hover {
            background: $color__attention__text !important;
            color: $color__core__white !important;
            box-shadow: inset 0 0 0 1px $color__attention__text;
        }

        &:active {
            background: $color__theme__primary--dark !important;
            color: $color__core__white !important;
            box-shadow: inset 0 0 0 1px $color__theme__primary--dark;
        }

        &:focus {
            outline: none;
        }

        &:focus-visible {
            box-shadow: inset 0 0 0 1px $color__core__black__primary,
                0 0 0 3px $color__utility__focusring;
        }
    }
}

/******************************************
* Button Styles
* (shared across Banner Notice & Options Dialog)
******************************************/
button {
    &#_evidon-accept-button,
    &#evidon-prefdiag-accept {
        background: $color__core__black__primary !important;
        color: $color__core__white !important;
        margin-left: 0 !important;
        margin-bottom: 16px !important;
    }

    &#_evidon-decline-button,
    &#evidon-prefdiag-decline {
        background: transparent !important;
    }

    &#_evidon-accept-button {
        order: 2 !important;

        @media screen and (min-width: $breakpoint__mobile__large) {
            order: 3 !important;
            margin-bottom: 0 !important;
            margin-left: 16px !important;
        }
    }

    &#_evidon-decline-button {
        order: 3 !important;

        @media screen and (min-width: $breakpoint__mobile__large) {
            order: 2 !important;
        }
    }
}

/******************************************
* Options Link
* When the .evidon-notice-link class
******************************************/
.evidon-notice-link {
    cursor: pointer;
}

#_evh-link {
    z-index: 1001;
    color: $color__core__black__primary !important;
    //position: fixed;
    //bottom: 0;
    //left: 16px;
    float: left;
    margin-bottom: unset !important;
    border-left: 1px solid rgba(166, 158, 146, 0.6) !important;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        box-shadow: 0 0 0 3px $color__utility__focusring;
    }

    &:hover {
        text-decoration: none !important;
        background: #f3f0eb !important;
    }

    &:active {
        background: #e4ddd3 !important;
    }

    #_evidon-button-text {
        line-height: 16px !important;
    }
}

/* Settings Re-entry Button */
#_evh-button { 
    display: none !important; 
    margin: 3px 3px 0 0;
}
.button-visible #_evh-button {
    display: block !important;
}

// Options Dialog Styles
.evidon-prefdiag-overlay {
    padding: 0 !important;
    width: 100% !important;
    //transition: transform 0.6s $motion__ease--out;
    //transform: translateX(-100%);
    animation: slide-in-left 0.6s $motion__ease--out;
    flex-flow: column !important;

    @media screen and (min-width: $breakpoint__tablet__standard) {
        width: percentage(20/26) !important;
    }

    @media screen and (min-width: $breakpoint__tablet__large) {
        width: percentage(16/26) !important;
    }

    @media screen and (min-width: $breakpoint__desktop__standard) {
        width: 50% !important;
    }

    &.animate-out {
        animation: slide-in-left 0.6s $motion__ease--out reverse;
    }
}

#evidon-prefdiag-sidebar {
    display: none !important;
}

#evidon-prefdialog-tabpanel {
    display: flex;
    flex-flow: column;
    inset: unset !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
}

#evidon-top-commands {
    display: none !important;
}

#evidon-prefdiag-navcontainer-icon {
    display: none !important;
}

#evidon-prefdiag-tabcontainer,
#evidon-prefdiag-navcontainer {
    position: unset !important;
    inset: unset !important;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
    background: transparent !important;
    flex: 1;
}

.evidon-prefdiag-message {
    a:link,
    a:visited {
        color: $color__theme__link !important;
    }
}

#evidon-prefdiag-nav-0,
#evidon-prefdiag-closeicon {
    display: none !important;
}

#evidon-prefdiag-tab-0 {
    display: block !important;
    position: relative;
    overflow: unset !important;
    padding: 104px 0 0 !important;
    height: unset !important;
    width: percentage(12/14) !important;
    margin: auto;

    @media screen and (min-width: $breakpoint__tablet__standard) {
        width: percentage(18/20) !important;
    }

    @media screen and (min-width: $breakpoint__tablet__large) {
        width: percentage(14/16) !important;
    }

    @media screen and (min-width: $breakpoint__desktop__standard) {
        width: percentage(11/13) !important;
    }

    &:before {
        content: '';
        height: 32px;
        width: 32px;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2 7.85l-4.8 3.06 4.8 3.05 4.8-3.05 4.8 3.05 4.8-3.05-4.8-3.06-4.8 3.06-4.8-3.06z' fill='%231E1919'/%3E%3Cpath d='M11.2 20.07l-4.8-3.05 4.8-3.06 4.8 3.06-4.8 3.05zM16 17.02l4.8-3.06 4.8 3.06-4.8 3.05-4.8-3.05zM16 24.15l-4.8-3.06 4.8-3.05 4.8 3.05-4.8 3.06z' fill='%231E1919'/%3E%3C/svg%3E");
        display: block;
        position: absolute;
        top: 24px;
        left: 0;
    }
}

#prc-title {
    font-size: 22px !important;
    line-height: 28px !important;
    font-weight: 400 !important;
    margin-top: 0 !important;
    margin-bottom: 12px !important;
}

#prc-message {
    margin: 0 0 24px !important;

    a:link,
    a:visited {
        color: $color__theme__link !important;

        &:focus {
            outline: none;
        }

        &:focus-visible {
            box-shadow: 0 0 0 3px $color__utility__focusring;
        }
    }
}

#prc-tp-cat-header,
#prc-tp-cat-message {
    display: none !important;
}

#prc-tp-cat-all,
.evidon-prefdiag-categoryheader {
    border-top: 1px solid $color__standard__border !important;
}

#prc-tp-cat-all {
    display: block !important;
    padding: 24px 0 !important;
    margin: 0 !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

#prc-tp-cat-all-toggle-label {
    font-size: 14px !important;
}

.evidon-prefdiag-categoryheader {
    position: relative !important;
    padding-right: 72px !important;
    padding-top: 24px !important;
    margin: 0 !important;

    .evidon-switch {
        position: absolute !important;
        right: 0;
        top: 24px;
    }
}

/* "Required" category toggle and label */

.evidon-prefdiag-categorydesc {
    padding-right: 72px !important;
    padding-bottom: 24px !important;
    font-size: 14px !important;
}

.evidon-prefdiag-categoryheader,
.evidon-prefdiag-categorydesc,
.evidon-prefdiag-subtitle,
.evidon-prefdiag-title,
.evidon-prefdiag-message {
    color: $color__standard__text !important;
}

#prc-tp-cat-container {
    display: flex;
    flex-flow: column;
    margin-top: 0 !important;
}

.evidon-switch {
    display: flex !important;
    width: 36px !important;
    height: 20px !important;
    outline: none;
    margin-right: 12px !important;

    .slideron.disabled {
        background-color: $color__disabled__background !important;
        box-shadow: inset 0 0 0 1px $color__disabled__background !important;
        cursor: default !important;
        pointer-events: none;

        &:before {
            background-color: #fff !important;
        }
    }
}

#prc-tp-cat-name-strictly_necessary {
    label {
        display: none !important;
    }
}

.evidon-switch-slider {
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px $color__standard__text;
    transition: 0.1s ease-in-out !important;

    &:before {
        top: 4px !important;
        height: 12px !important;
        width: 12px !important;
        background: $color__standard__text !important;
        transition: 0.1s ease-in-out !important;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.06) !important;
    }

    &.slideron {
        background-color: $color__core__black__primary !important;
        box-shadow: inset 0 0 0 1px $color__core__black__primary;

        &:hover {
            background-color: #312a25 !important;
            box-shadow: inset 0 0 0 1px #312a25;
        }

        &:before {
            background: #fff !important;
        }
    }

    .evidon-switch:focus-visible input + & {
        box-shadow: inset 0 0 0 1px #000, 0 0 0 3px $color__utility__focusring !important;
    }
}

#evidon-prefdiag-tabfooter {
    position: unset !important;
    padding: 24px percentage(1/14) !important;
    display: flex !important;
    flex-flow: column-reverse;
    height: unset !important;
    border-top: $color__standard__border;
    order: 1;

    @media screen and (min-width: $breakpoint__mobile__large) {
        padding: 34px percentage(1/14) !important;
    }

    @media screen and (min-width: $breakpoint__tablet__standard) {
        flex-flow: row;
        justify-content: flex-end;
        padding: 24px percentage(1/20) !important;
    }

    @media screen and (min-width: $breakpoint__tablet__large) {
        padding: 24px percentage(1/16) !important;
    }

    @media screen and (min-width: $breakpoint__desktop__standard) {
        padding: 26px percentage(1/13) !important;
    }

    #evidon-prefdiag-accept {
        @media screen and (min-width: $breakpoint__tablet__standard) {
            margin-bottom: 0 !important;
            margin-left: 12px !important;
        }
    }
}

#evidon-prefdiag-background {
    background: rgba(#000, 0.18) !important;
    animation: fade-in 0.6s $motion__ease--out;

    &.animate-out {
        animation: fade-in 0.6s $motion__ease--out reverse;
    }
}

/* Added button scss for button change  */
.evidon-consent-button {
    height: auto;
    width: auto;
    position: fixed;
    bottom: 12px;
    left: 16px;
    z-index: 999;
}

/* Settings Re-entry Collapse Toggle Button */
.collapse-toggle-label {
    width: 40px;
    height: 42px;
    border: 1px solid rgba(166,158,146,.6);
    border-left: none;
    cursor: pointer;
    background: #fff;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    margin-left: 0;
}

.collapse-toggle-label input:checked + .collapse-icon {
    transform: rotate(180deg);
}

.evidon-consent-button.expanded {
    transform: translateX(0);
    transition: transform 200ms ease-out;
}

.evidon-consent-button.collapsed {
    transform: translateX(calc(-100% - 16px)) translateX(41px);
    transition: transform 200ms ease-out;
}
@import '../../styles/component.scss';

.moduleContainerWrap {
  composes: container from global;
}

.moduleContainerRow {
  composes: row from global;
}

.videoTextCol,
.videoCol,
.topicsCol {
  composes: col-4 col-md from global;
}

.topicWrap {
  border-top: solid 1px color('black');
  border-bottom: solid 1px color('black');
  padding: $grid-gutter-width / 2 0;
}

.topicName {
  @include fontBaseReset();
  @include sharpGroteskMedium();
  
  line-height: 1.5;
  position: relative;
  display: block;

  &::after {
    position: absolute;
    right: 0.25em;
    width: 1rem;
    height: 1rem;
    background-image: url(/images/assets/right-arrow.svg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 999999;
    cursor: pointer;
    content: '';
    top: 0.25em;
  }
}

.sectionTitle {
  composes: section-header-text from global;
}

.titleColTopics {
  composes: col-4 from global;
}

.titleColVideo {
  composes: col-4 col-md from global;
}